const invitation = {
    find_your_tenants: 'Trova i tuoi inquilini',
    find_tenants_description: 'Il tuo indirizzo email potrebbe già essere registrato con più inquilini. Puoi scegliere di unirti a quelli esistenti o continuare a crearne uno nuovo.',
    create_new_tenant: 'Crea un nuovo inquilino',
    email_not_match_title: "Al momento hai effettuato l'accesso come\n{{email}}",
    email_not_match_description: "Effettua l'accesso con l'account corretto per accettare l'invito e diventare un membro dell'organizzazione.",
    switch_account: 'Accedi con un altro account',
    invalid_invitation_status: "Invito non valido. Contatta l'amministratore e riprova.",
    invitation_not_found: "Invito non trovato. Contatta l'amministratore.",
};
export default Object.freeze(invitation);
