const logs = {
    page_title: 'Log di Audit',
    title: 'Log di Audit',
    subtitle: 'Visualizza i dati di registro degli eventi di autenticazione effettuati dai tuoi utenti',
    event: 'Evento',
    user: 'Utente',
    application: 'Applicazione',
    time: 'Tempo',
    filter_by: 'Filtra per',
};
export default Object.freeze(logs);
