const menu = {
    profile: 'Profilo',
    language: 'Lingua',
    appearance: {
        label: 'Aspetto',
        light: 'Modalità chiaro',
        dark: 'Modalità scuro',
        system: 'Sincronizza con sistema',
    },
    sign_out: 'Esci',
};
export default Object.freeze(menu);
