const quota_table = {
    quota: {
        title: 'Principi',
        base_price: 'Prezzo base',
        mau_limit: 'Limite di MAU',
        included_tokens: 'Token inclusi',
    },
    application: {
        title: 'Applicazioni',
        total: 'Totale applicazioni',
        m2m: 'Machine-to-machine',
        third_party: 'App di terze parti',
    },
    resource: {
        title: 'Risorse API',
        resource_count: 'Numero di risorse',
        scopes_per_resource: 'Permessi per risorsa',
    },
    branding: {
        title: 'UI e branding',
        custom_domain: 'Dominio personalizzato',
        custom_css: 'CSS personalizzato',
        logo_and_favicon: 'Logo e favicon',
        bring_your_ui: 'Porta la tua interfaccia',
        dark_mode: 'Modalità scura',
        i18n: 'Internazionalizzazione',
    },
    user_authn: {
        title: 'Autenticazione utente',
        omni_sign_in: 'Accesso onnicomprensivo',
        password: 'Password',
        passwordless: 'Senza password - E-mail e SMS',
        email_connector: 'Connettore e-mail',
        sms_connector: 'Connettore SMS',
        social_connectors: 'Connettori sociali',
        standard_connectors: 'Connettori standard',
        built_in_email_connector: 'Connettore e-mail integrato',
        mfa: 'Autenticazione a più fattori',
        sso: 'SSO aziendale',
        impersonation: 'Impersonificazione',
    },
    user_management: {
        title: 'Gestione utenti',
        user_management: 'Gestione utenti',
        roles: 'Ruoli',
        machine_to_machine_roles: 'Ruoli machine-to-machine',
        scopes_per_role: 'Permessi per ruolo',
    },
    organizations: {
        title: 'Organizzazione',
        organizations: 'Organizzazioni',
        organization: 'Organizzazione',
        organization_count: 'Conteggio organizzazioni',
        allowed_users_per_org: 'Utenti per organizzazione',
        invitation: 'Invito (Gestione API)',
        org_roles: 'Ruoli organizzazione',
        org_permissions: 'Permessi organizzazione',
        just_in_time_provisioning: 'Provisioning just-in-time',
    },
    support: {
        /** UNTRANSLATED */
        title: 'Support',
        community: 'Community',
        customer_ticket: 'Ticket di assistenza',
        premium: 'Premium',
        email_ticket_support: 'Supporto tramite ticket e-mail',
        soc2_report: 'Rapporto SOC2',
        hipaa_or_baa_report: 'Rapporto HIPAA/BAA',
    },
    developers_and_platform: {
        title: 'Sviluppatori e piattaforma',
        hooks: 'Webhooks',
        audit_logs_retention: 'Conservazione dei log di audit',
        jwt_claims: 'JWT claims',
        tenant_members: 'Membri del tenant',
    },
    unlimited: 'Illimitato',
    contact: 'Contatta',
    monthly_price: '${{value, number}}/mese',
    days_one: '{{count, number}} giorno',
    days_other: '{{count, number}} giorni',
    add_on: 'Aggiuntiva',
    tier: 'Livello{{value, number}}: ',
    million: '{{value, number}} milioni',
    mau_tip: 'MAU (utenti attivi mensili) significa il numero di utenti unici che hanno scambiato almeno un token con Logto in un ciclo di fatturazione.',
    tokens_tip: 'Tutti i tipi di token emessi da Logto, inclusi token di accesso, token di aggiornamento, ecc.',
    mao_tip: 'MAO (Organizzazione attiva mensile) indica il numero di organizzazioni uniche che hanno almeno un MAU (utente attivo mensile) in un ciclo di fatturazione.',
    third_party_tip: "Usa Logto come provider di identità OIDC per l'accesso e il rilascio di permessi di app di terze parti.",
    included: '{{value, number}} incluso',
    included_mao: '{{value, number}} MAO inclusi',
    extra_quota_price: 'Quindi ${{value, number}} al mese / ognuno dopo',
    per_month_each: '${{value, number}} al mese / ognuno',
    extra_mao_price: 'Quindi ${{value, number}} per MAO',
    per_month: '${{value, number}} al mese',
    per_member: 'Quindi ${{value, number}} per membro',
};
export default Object.freeze(quota_table);
