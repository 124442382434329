const contact = {
    title: 'Ottieni Aiuto',
    description: 'Per richieste di assistenza o commenti sui prodotti, contattaci tramite i seguenti metodi.',
    discord: {
        title: 'Unisciti alla community di Discord',
        description: 'Connettiti con altri sviluppatori per trovare soluzioni',
        button: 'Unisciti',
    },
    github: {
        title: 'Comunica su GitHub',
        description: 'Crea un problema e invialo su GitHub',
        button: 'Apri',
    },
    email: {
        title: 'Contattare il supporto tramite e-mail',
        description: 'Invia una e-mail per ulteriori informazioni e assistenza',
        button: 'Invia',
    },
    reserve: {
        title: 'Prenota il tuo tempo con il team Logto',
        description: 'Prenota rapidamente una sessione per una chat dal vivo',
        button: 'Prenota',
    },
};
export default Object.freeze(contact);
