const demo_app = {
    title: "Hai effettuato l'accesso anteprima live con successo!",
    subtitle: 'Ecco le informazioni sul tuo utente:',
    username: 'Nome utente: ',
    user_id: 'ID utente: ',
    sign_out: "Esci dall'anteprima live",
    continue_explore: 'O continua ad esplorare',
    customize_sign_in_experience: "Personalizza l'esperienza di accesso",
    enable_passwordless: "Abilita l'accesso senza password",
    add_social_connector: 'Aggiungi connettore social',
};
export default Object.freeze(demo_app);
